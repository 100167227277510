import React, { useState, useEffect } from "react";
import DataContent from "./DataContent";
import { Helmet } from "react-helmet";

export default function TermsConditions() {
  const Site_Url = "https://translationservice.in.net/";
  const BASE_URL = Site_Url + "admin/API/";

  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  return (
    <>
      <Helmet>
        <title>Terms & Conditions</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <DataContent
        title="Terms & Conditions"
        desc={webSetting.terms_conditions_desc}
      />
    </>
  );
}
